.note {
  display: flex;
  flex-direction: column;
  background: var(--light-background-2);
  // margin: var(--spacing-xl) 0 var(--spacing-sm) 0;
  padding: var(--spacing-sm) var(--spacing-xs);
  border-radius: var(--border-radius-md);

  .content {
    display: flex;
    align-items: center;

    .iconWrapper {
      width: 40px;
      margin-right: var(--spacing-xs);
    }

    .text {
      margin: 0;
    }
  }
}
