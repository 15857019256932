.cartContainer {
  padding: var(--spacing-lg) 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "signUpPrompt cartSummary"
    "cartItems cartSummary"
    "charityBanners cartSummary";

  @media (max-width: 992px) {
    grid-template-columns: 100%;
    grid-template-areas:
      "signUpPrompt"
      "cartSummary"
      "cartItems"
      "charityBanners";
  }

  .signUpPrompt {
    display: flex;
    align-items: center;
    margin: 0 auto var(--spacing-lg) 0;
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-sm);
    background: var(--dark-background-translucent);
    color: var(--light-text);

    .icon {
      display: inline-flex;
      align-items: center;
      width: 20px;
      height: 100%;
      margin-right: var(--spacing-xs);
    }

    .signUpPromptText {
      margin: 0;
    }

    .clerkButton {
      display: inline-block;
      padding: 0;
      // margin: 0 0.2em;
      border: none;
      background: transparent;
      text-decoration: underline;
      color: var(--light-text);
      white-space: nowrap;

      &:hover {
        background: transparent !important;
        // color: var(--dark-text) !important;
      }
    }
  }

  .cartContent {
    position: relative;
    grid-area: cartItems;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    // justify-content: center;
    align-items: center;
    min-height: 50vh;
    overflow-x: hidden;

    &.active {
      min-height: 110vh;
      height: fit-content;
    }

    .emptyCartMessage {
      color: var(--dark-text);
      text-align: center;
      font-size: var(--font-size-md-sm);
      margin-top: 20vh;
    }
  }

  .cartSummary {
    grid-area: cartSummary;
  }

  .charityBanners {
    grid-area: charityBanners;
  }
}
