.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-md);
  position: relative;
  width: 100%;

  @media (max-width: 992px) {
    margin-bottom: var(--spacing-xl);
  }

  &.fullWidth {
    width: 100%;
  }

  &.halfWidth {
    min-width: 40%;
    width: 50%;
  }

  &.thirdWidth {
    min-width: 28%;
    width: 33%;
  }

  .label {
    color: gray;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;

    .required {
      color: red;
    }
  }

  .instructions {
    font-size: var(--font-size-xs);
    font-style: italic;
    margin-bottom: var(--spacing-xs);
  }

  .error {
    position: relative;
    height: 0;
    border-color: red;
    overflow: visible;

    .errorMessage {
      color: red;
      font-size: var(--font-size-xs);
      margin-top: 5px;
    }
  }
}
