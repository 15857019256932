.charityLogoBar {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 4fr 1.5fr;
  grid-gap: var(--spacing-sm);
  align-items: center;
  width: clamp(240px, 95%, 90vw);
  // max-width: 90vw;
  margin: var(--spacing-sm) 0;
  padding: var(--spacing-sm) 0;
  border-radius: var(--border-radius-md);
  background: var(--light-background-4);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    margin-left: auto;
    margin-right: auto;
    justify-items: center;
  }

  .iconWrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    margin: 0 var(--spacing-lg) 0 var(--spacing-sm);

    .icon {
      object-fit: contain;
      object-position: center;

      // @media (max-width: 768px) {
      //   object-position: center;
      // }
    }
  }

  .text {
    margin: 0;
    padding: 0 var(--spacing-sm);
  }

  .charityLink {
    font-weight: 700;
    color: var(--dark-text);
    padding: 0 var(--spacing-sm);
  }
}
