.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--dark-border);
  border-radius: var(--border-radius-md);
  text-align: center;
  outline: 2px solid transparent;
  transition: var(--transition-md);
  pointer-events: none;

  &:hover {
    cursor: pointer;
    outline: 2px solid var(--dark-border);
  }

  .text {
    font-weight: 500;
    line-height: 110%;
  }
}

div:global(.checked) {
  .container {
    outline: 2px solid var(--dark-border);
  }
}
