.rowFieldset {
  display: flex;
  flex-wrap: wrap;

  div:global(.inputWrapper) {
    // flex: 1;

    &:not(:last-of-type),
    &:not(:nth-child(2)) {
      margin-right: var(--spacing-sm);

      @media (max-width: 768px) {
        margin-right: 0;
      }
    }

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }
}
