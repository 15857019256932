.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;

  img {
    object-fit: contain;
    object-position: top;
    padding-left: var(--spacing-lg);

    @media (max-width: 992px) {
      padding: 0;
    }
    @media (max-width: 480px) {
      object-fit: cover;
      padding-left: 0;
    }
  }
}
