.userButton {
  border: 0;
  background: transparent;
  border-radius: var(--border-radius-sm);
  transition: var(--transition-md);

  &.custom {
    position: relative;
    // &:hover {
    //   background: inherit !important;
    //   color: inherit !important;
    // }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
