.container {
  display: flex;
  position: relative;
  // width: 100%;
  @media (width < 768px) {
    flex-direction: column;
    // aspect-ratio: 16 / 9;
    aspect-ratio: 683 / 330;
  }

  .detectHover {
    position: fixed;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 200;
    // pointer-events: all;

    @media (width < 768px) {
      display: none;
    }

    &.activated {
      display: none;
    }
  }

  .videoContainer {
    position: relative;
    width: 100%;
    max-height: 40vw;

    @media (max-width: 992px) {
      // aspect-ratio: 16 / 9;
      aspect-ratio: 683 / 330;
      max-height: 50vw;
    }

    @media (max-width: 768px) {
      // padding-top: 56.25%;
      // height: 85vw;

      max-height: 65vw;

      @media (max-width: 600px) {
        max-height: 70vw;
      }

      @media (max-width: 480px) {
        max-height: 80vw;
      }
    }

    div:global(wn-react-player) {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.desktopVideoContainer {
      position: relative;

      display: block;

      .pageLinkContainer {
        position: absolute;
        bottom: 50px;
        display: flex;
        justify-content: center;
        width: 100%;
        opacity: 1;
        transition: all 0.3s ease-in-out;

        &.hide {
          opacity: 0.4;
        }

        .logoWrapper {
          margin-right: var(--spacing-sm);
        }
      }
    }

    .image {
      object-fit: contain;

      //   @media (max-width: 1200px) {
      //     object-fit: contain;
      //   }
    }
  }
  .mobileText {
    @media (min-width: 768px) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    height: 110px;
    padding: var(--spacing-xs);

    @media (min-width: 768px) {
      display: none;
    }

    .title {
      flex: 1 1 auto;
      // width: clamp(200px, 70%, 100%);
      margin-bottom: 0;
      padding-right: var(--spacing-sm);
      font-size: var(--font-size-sm);
      font-weight: 600;
      line-height: 1.3em;
      color: var(--dark-text);
      // text-shadow: 0px 0px 6px var(--dark-text);
    }
    .metadata {
      font-size: var(--font-size-xs);
    }
  }
}
