.radioButtons {
  position: relative;
  display: flex;
  gap: var(--spacing-sm);
  height: 100%;
  padding: var(--spacing-xs);
  overflow-y: scroll;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 640px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }

    @media (max-width: 420px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    }

    &.customLabels {
      .inputContainer {
        width: 100%;
        flex: 0 1 140px;
        // height: 130px;
        aspect-ratio: 1.3 / 1;
        position: relative;

        @media (max-width: 768px) {
          min-width: 100%;
          width: 100%;
          height: 100%;
        }
      }

      &.threeItemRow {
        .inputContainer {
          flex: 0 1 25%;
          height: 120px;

          @media (max-width: 768px) {
            flex: 1 1 140px;
          }
        }
      }
    }
  }

  .customLabel {
    display: none;
  }

  .inputContainer {
    display: flex;
    width: 100%;
    position: relative;

    input {
      margin-right: var(--spacing-xs);
    }
  }
}

.radioButtonLabel {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}
