.promoCodeContainer {
  display: flex;
  justify-content: flex-start;
  //   align-items: flex-end;
  flex-wrap: wrap;
  margin-top: var(--spacing-md);

  .promoCodeInputWrapper {
    flex: 1 1 auto;
    position: relative;
    margin-top: var(--spacing-xs);
    margin-right: var(--spacing-sm);
    border: 1px solid var(--light-border);
    border-radius: var(--border-radius-sm);

    @media (max-width: 480px) {
      width: 100%;
      margin-right: 0;
    }

    .label {
      position: absolute;
      top: -10px;
      left: 5px;
      padding: 0 var(--spacing-xs);
      font-size: var(--font-size-xs);
      background: var(--dark-background);
    }

    .promoCodeInput {
      padding: var(--spacing-xs);
      padding-top: calc(var(--spacing-xs) + 2px);
      //   border-bottom: 2px solid var(--light-text);
      color: var(--light-text);
      font-size: var(--font-size-sm);
      text-transform: uppercase;
    }
  }

  .promoCodeButtonWrapper {
    margin-top: var(--spacing-xs);
    flex: 1 1 auto;
    .promoCodeButton {
      position: relative;
      flex-basis: 160px;
    }
  }
}

.appliedPromoCode {
  display: flex;
  align-items: center;
}
