.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 100%;
  // background: var(--light-background);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .textContainer {
    position: absolute;
    top: 10%;
    width: 100%;
    left: 0;
    right: 0;
    color: var(--light-text);
    font-weight: 600;

    @media (max-width: 992px) {
      top: 5%;
    }

    @media (width >= 768px) and (width <= 992px) {
      top: 10%;
      left: 2%;
    }

    @media (width <= 768px) {
      top: 0;
      height: 100%;
    }

    @media (width <= 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -50px;
    }

    .titleText {
      margin-left: 12%;
      //   width: max-content;
      max-width: 700px;

      @media (max-width: 992px) {
        margin: auto;
        padding: 0 var(--spacing-sm) var(--spacing-sm) var(--spacing-sm);
      }
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // height: 100%;

        text-align: center;
      }

      .modal {
        // min-height: 200px;
        // padding: 0 var(--spacing-md) var(--spacing-lg) 0;
        // background: var(--dark-background-transparent);

        border-radius: var(--border-radius-md);

        @media (width < 768px) {
          display: none;
        }

        .title {
          margin-bottom: var(--spacing-lg);
          font-size: var(--font-size-xxxl);
          line-height: 1.3em;
          color: var(--light-text);
          text-shadow: 2px 2px 10px var(--dark-text);

          @media (max-width: 480px) {
            height: 50%;
            font-size: var(--font-size-xl);
          }
        }
        .text {
          font-size: var(--font-size-md);
          line-height: 1.3em;
          color: var(--light-text);
          text-shadow: 0px 0px 6px var(--dark-text);

          @media (max-width: 768px) {
            display: none;
          }

          @media (max-width: 480px) {
            font-size: var(--font-size-md-sm);
          }
        }
      }
      .buttonWrapper {
        display: block;
        margin-top: var(--spacing-lg);
        width: clamp(220px, 300px, 320px);
        a {
          font-size: var(--font-size-md-sm) !important;
        }

        @media (max-width: 768px) {
          margin-top: 0;
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  .imageContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: max-content;
    // max-height: 90dvh;
    // aspect-ratio: 16 / 9;
    aspect-ratio: 683 / 330;
    img {
      // aspect-ratio: 16 / 9;
      aspect-ratio: 683 / 330;
      object-fit: cover;

      //   @media (max-width: 1200px) {
      //     object-fit: contain;
      //   }
    }
  }

  .mobileSubText {
    display: flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    height: 100%;
    max-height: 110px;
    padding: var(--spacing-xs);

    p {
      margin-top: 0;
    }

    @media (min-width: 768px) {
      display: none;
    }

    .text {
      flex: 1 1 auto;
      align-self: flex-start;
      width: clamp(200px, 70%, 100%);
      margin-bottom: 0;
      padding-right: var(--spacing-sm);

      line-height: 1.3em;
      color: var(--dark-text);
      // text-shadow: 0px 0px 6px var(--dark-text);

      div:global(.wn-slide-text) {
        position: relative;
      }

      p:global(.wn-slide-title) {
        margin-bottom: var(--spacing-xxs);
        font-size: var(--font-size-md-sm);
        font-weight: 600;

        @media (max-width: 340px) {
          font-size: var(--font-size-sm);
        }
      }
      p:global(.wn-slide-subtitle) {
        font-size: var(--font-size-xs);
        margin-bottom: 0;

        @media (max-width: 340px) {
          font-size: var(--font-size-xs);
        }
      }
    }

    .buttonWrapper {
      align-self: center;
      width: clamp(120px, 30%, 100%);
    }
  }
}
