.phoneInputContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .countryCodeDropdown {
    // flex: 1 1 auto;
    width: 220px;
    height: 40px;
    margin-right: var(--spacing-sm);

    @media (max-width: 1024px) {
      flex: 1 1 auto;
      width: 100%;
      margin-right: 0;
    }
  }

  .phoneInputWrapper {
    flex: 3 1 auto;
    position: relative;
    display: flex;
    align-items: center;
    min-width: 180px;

    @media (max-width: 1024px) {
      margin-top: var(--spacing-sm);
    }

    .phoneInputPrefix {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px;
      height: 40px;
      padding: 0 var(--spacing-xs);
      border: 1px solid var(--dark-background);
      border-right: none;
      border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
      background: var(--overlay);
    }
    .phoneInput {
      width: 100%;
      height: 40px;
      border-left: none;
      border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    }
  }
  .hiddenInput {
    display: none;
  }
}
