.container {
  padding: 2px 2px var(--spacing-lg) 2px;
  overflow-y: auto;
  mapbox-search-box {
    input:global {
      padding: var(--spacing-md-sm) var(--spacing-lg);
      outline: 2px solid var(--dark-border);
      border-radius: var(--border-radius-md);
      box-shadow: unset;
      border: unset;

      &:active,
      &:focus {
        border: unset;
        outline: 1.5px solid var(--dark-border);
      }
    }
    button {
      &:hover {
        color: var(--dark-text) !important;
        cursor: pointer;
      }
    }
  }
}
