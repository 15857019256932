.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  .label {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: stretch;
    // height: 100%;
    max-height: 100%;
    padding: 0 var(--spacing-xxs) 0px var(--spacing-xxs);

    .labelText {
      display: block;
      padding-bottom: var(--spacing-xs);
      // height: 5%;
      // text-align: center;
    }

    div:global(.inputWrapper) {
      flex: 0 1 auto;
      margin-bottom: 0;
      height: fit-content;
      // max-height: 320px;
      padding-bottom: var(--spacing-lg);
      overflow-y: auto;

      @media (max-width: 480px) {
        padding-bottom: var(--spacing-sm);
      }
    }

    .instructions {
      display: block;
      font-size: var(--font-size-xs);
      font-style: italic;
      margin-bottom: var(--spacing-sm);
    }
  }
}
