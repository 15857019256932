.results {
  display: flex;
  height: 80vh;
  width: 100%;
  padding: 0 var(--spacing-sm);

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  .closeButton {
    display: flex;
    position: absolute;
    top: 10px;
    right: 40px;
    width: 40px;
    height: 40px;
    z-index: 100;

    @media (max-width: 768px) {
      display: none;
    }
  }
  .column {
    overflow-y: auto;
  }
  .column1 {
    flex: 1 1 65%;
  }
  .sideColumns {
    flex: 1 1 35%;

    display: flex;
    flex-direction: column;
    .column {
      height: 50%;
    }
    .column2 {
      width: 100%;
    }
    .column3 {
      width: 100%;
    }
  }
  .tabContainer {
    width: 100%;
    // padding: 0 var(--spacing-md);
    overflow-y: auto;
  }
  .emptySearchBoundary {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 15vh var(--spacing-sm) 0 var(--spacing-sm);
    font-size: var(--font-size-md-sm);

    @media (max-width: 768px) {
      padding-top: 12vh;
    }

    .suggestedSearchButton {
      background: transparent;
      // border: 1px solid black;
      border: none;
      text-decoration: underline;
      border-radius: 25px;
      padding: 0.3em 0.1em 0.3em 0.2em;
      margin-left: 0.2em;
    }
  }
  .emptyResultsBoundary {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: var(--spacing-lg);
    text-align: center;
  }
}
