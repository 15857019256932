.promoCode {
  display: block;
  max-width: max-content;
  margin-top: var(--spacing-md);
  padding: var(--spacing-xxs) var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  background: var(--light-background);
  color: var(--dark-text);
  pointer-events: none;

  .removePromoCodeButton {
    margin-left: var(--spacing-xs);
    padding: 0 var(--spacing-xs);
    border: none;
    border-radius: var(--spacing-xxs);
    background: transparent;
    pointer-events: all;
    transition: all 0.3s ease-in-out;

    .removePromoCodeButtonIcon {
      font-weight: 700;
    }
  }
}
