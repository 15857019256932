.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: var(--spacing-sm);
  max-width: 320px;
  padding: var(--spacing-xs);
  border: 2px solid var(--dark-border);
  border-width: 2px 2px 0 2px;
  background: var(--light-background);
  border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
  transition: var(--transition-md);
  transform: translateY(100%);
  animation: slideUp 0.5s forwards;
  z-index: 100;
  overflow: hidden;
}

.closeButtonContainer {
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
  right: 0;
}

.closeButton {
  padding: var(--spacing-xs);
  cursor: pointer;
  line-height: 80%;
  font-weight: 700;
  border-radius: 0 0 0 var(--border-radius-md);
}

.icon {
  margin: auto;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
