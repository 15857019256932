.iconBoxes {
  display: flex;
  flex-wrap: wrap;
  .iconBoxWrapper {
    flex: 1 1 auto;
    width: clamp(260px, 50%, 100%);
  }
}
.imageWrapper {
  margin: 0 auto;
  width: clamp(280px, 100%, 480px);
  // max-width: 600px;
}
