.container {
  display: flex;
  flex-direction: column;
  background: var(--dark-background);
  min-height: 280px;
  width: 100%;
  max-width: 95vw;
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
  border-radius: var(--border-radius-md);
  color: var(--light-text);

  .header {
    color: var(--light-text);
    font-weight: 700;
  }

  .total {
    margin: var(--spacing-xs) 0 var(--spacing-sm) 0;
    padding-top: var(--spacing-xs);
    border-top: 1px solid var(--light-border);
    text-align: right;
    span {
      font-weight: 500;
    }
  }

  .lineItems {
    position: relative;
    width: 100%;
  }

  .noteContent {
    margin: 0;
    // margin-bottom: var(--spacing-sm);
    // font-style: italic;
    font-size: var(--font-size-xs);
    color: var(--light-text);
  }

  .actionsContainer {
    margin-top: var(--spacing-xl);
    width: 100%;
  }
}
