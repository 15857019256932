.impactArea {
  position: relative;
  background: #43596b /*#8da5b8*/;
  padding: 100px 0;
  padding-bottom: 140px;
  overflow: hidden;

  .impactBackground {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-image: url("/assets/img/impact-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    pointer-events: none;
  }

  .title {
    margin-bottom: 40px !important;
    text-align: left;
    max-width: 680px;

    h3 {
      font-weight: 600;
      font-size: 36px;
      line-height: 115%;
      text-align: left;
      color: #ffffff;
      margin-bottom: 20px;
    }

    p {
      font-weight: 600;
      font-size: 26px;
      line-height: 115%;
      text-align: left;
      color: #ffffff;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .column1 {
      display: flex;
      flex: 1 1 auto;
      width: clamp(440px, 50%, 100%);

      @media (max-width: 992px) {
        margin-bottom: var(--spacing-lg);
      }

      @media (max-width: 480px) {
        min-width: 300px;
      }

      .sliderWrapper {
        max-width: 520px;
        // aspect-ratio: 16 / 11.5;
        display: block;
        position: relative;
        aspect-ratio: 127/85;

        @media (max-width: 992px) {
          margin: auto;
        }

        @media (max-width: 768px) {
          width: 100%;
          max-height: 100%;
        }
      }
    }

    .column2 {
      flex: 1 1 auto;
      width: clamp(350px, 50%, 100%);

      @media (max-width: 480px) {
        min-width: 300px;
      }

      .text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 var(--spacing-sm);
        @media (min-width: 992px) {
          padding-left: var(--spacing-lg);
        }
        p {
          font-size: 20px;
          line-height: 150%;
          color: var(--light-text);
          margin-bottom: 30px;
          margin-top: -8px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}
