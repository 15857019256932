.container {
  position: relative;
  width: 100%;
  height: 100%;

  .imageWrapper {
    position: relative;
    width: 100%;
    min-height: 520px;
    height: 90dvh;

    img {
      object-fit: cover;
    }
  }

  .formContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form {
      position: relative;
      width: clamp(500px, 75%, 800px);
      height: clamp(400px, 95%, 600px);

      // padding: var(--spacing-xl) calc(var(--spacing-lg) * 2);
      padding: var(--spacing-md) calc(var(--spacing-lg) * 2);
      background: var(--light-background);

      @media (max-width: 992px) {
        width: clamp(300px, 80%, 95vw);
        // height: clamp(100px, 80%, 80vw);
        padding: var(--spacing-lg);
        aspect-ratio: unset;
      }

      @media (max-width: 480px) {
        display: flex;
        height: auto;
        min-height: 420px;
        max-height: 85%;
        padding: var(--spacing-sm);
      }

      .page {
        height: 100%;
        // overflow-y: auto;

        p {
          margin: 0;
        }

        .title {
          margin-bottom: var(--spacing-xs);
          font-size: var(--font-size-lg);
          font-weight: 500;
          line-height: 140%;
          text-align: center;

          @media (max-width: 768px) {
            font-size: var(--font-size-md-lg);
          }

          @media (max-width: 480px) {
            font-size: var(--font-size-md);
          }
          @media (max-width: 420px) {
            font-size: var(--font-size-md-sm);
          }
        }

        .inputs {
          position: relative;
          right: 0;
          bottom: 0;
          left: 0;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          height: 85%;
          // width: 100%;
          // max-height: 400px;
          overflow-y: hidden;
          margin: 0;

          @media (max-width: 480px) {
            height: 95%;
            padding-bottom: var(--spacing-lg);
          }
        }
      }
    }
  }
}
